body,
html {
  overflow: hidden;
}

body,
html,
#root,
canvas {
  margin: 0;
  padding: 0;
  height: 100vh;
  user-select: none;
}

a {
  text-decoration: none;
  color: black;
}
